<template>
  <div class="visit-detail">
    <span class="back-icon" @click="$router.go(-1)">
      <i class="iconfont its_gy_fanhui"></i>
    </span>
    <div class="content">
      <h3>订单详情</h3>
      <el-row>
        <el-col :span="4">{{ info.lineName }}</el-col>
        <el-col :span="4">{{
          orderStatusList.find((t) => t.value == info.orderStatus).label
        }}</el-col>
        <el-col :span="16">订单编号：{{ info.orderId }}</el-col>
        <el-col :span="4">下单人：{{ info.passengerName }}</el-col>
        <el-col :span="4">联系电话：{{ info.passengerPhone }}</el-col>
        <el-col :span="4">乘客数量：{{ info.passengerCount }} </el-col>
        <el-col :span="4">有效乘车人数：{{ info.validPassengersNum }}</el-col>
        <el-col :span="6">下单时间：{{ info.orderCreateTime }}</el-col>
      </el-row>
      <h3>回访信息</h3>
      <el-row class="detail-row">
        <el-col :span="6">
          <span>回访时间：</span>
          <div>{{ info.interviewTime }}</div>
        </el-col>
        <el-col :span="6">
          <span>被访人：</span>
          <div>{{ info.interviewee }}</div>
        </el-col>
        <el-col :span="6">
          <span>回访人：</span>
          <div>{{ info.interviewer }}</div>
        </el-col>
        <el-col :span="6">
          <span>回访方式：</span>
          <div>{{ info.interviewWay == 0 ? "电话回访" : "微信回访" }}</div>
        </el-col>
        <el-col :span="24">
          <span>回访内容：</span>
          <div>
            {{ info.interviewContent == 0 ? "产品使用情况" : "客诉处理" }}
          </div>
        </el-col>
        <el-col :span="24">
          <span>回访记录：</span>
          <quill-editor
            ref="myTextEditor"
            v-model="info.interviewRecord"
            :options="editorOption"
            :disabled="true"
          ></quill-editor>
        </el-col>
        <el-col :span="24">
          <span>附件：</span>
          <div class="files">
            <template v-for="(item, index) in info.Files">
              <li
                v-if="item.url && item.type.indexOf('image') != -1"
                :key="index"
              >
                <i class="el-icon-picture"></i>
                {{ item.name }}
                <div>
                  <el-image
                    :src="fileBaseUrl + item.url"
                    :preview-src-list="[fileBaseUrl + item.url]"
                    style="width: 200px"
                  ></el-image>
                </div>
              </li>
              <li
                v-else-if="item.url && item.type.indexOf('audio') != -1"
                :key="index"
              >
                <i class="el-icon-microphone"></i>
                <a :href="fileBaseUrl + item.url" :download="item.name">
                  {{ item.name }}
                </a>
              </li>
              <li
                v-else-if="item.url && item.type.indexOf('video') != -1"
                :key="index"
              >
                <i class="el-icon-video-camera"></i>
                <a :href="fileBaseUrl + item.url" :download="item.name">
                  {{ item.name }}
                </a>
              </li>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { queryVisitRecordDetail } from "@/api/lib/api.js";
// import request from "@/api/lib/request.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import config from "@/api/static/host.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      info: {},
      // fileBaseUrl: process.env == "production" ? config.imgURL : "/apiTarget/",
      fileBaseUrl: config.imgURL,
      // 富文本编辑器配置
      editorOption: {
        placeholder: "编辑内容",
        modules: {},
      },
    };
  },
  beforeMount() {
    let query = this.$route.query;
    // Object.assign(this.info, params);
    this.queryDetail(query.id);
  },
  methods: {
    queryDetail(id) {
      if (id) {
        queryVisitRecordDetail({ id }).then((res) => {
          if (res.code == "SUCCESS" && res.data) {
            this.info = res.data;
            const files = res.data.interviewFile?.map((elem) => {
              try {
                let fileInfo = elem.split(":");
                return {
                  url: fileInfo[0],
                  type: fileInfo[1],
                  name: fileInfo[2] ?? fileInfo[0].split("/").pop(),
                };
              } catch (e) {
                console.log(e);
                return {};
              }
            });
            this.$set(this.info, "Files", files);
            console.log(this.info);
          }
        });
      }
    },
    // 下载跨域文件
    // downloadFile(e) {
    //   console.log(e);
    //   request({
    //     baseURL: this.fileBaseUrl,
    //     url: e.url,
    //     method: "get",
    //   }).then((res) => {
    //     console.log(res);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.visit-detail {
  box-sizing: border-box;
  padding: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .back-icon {
    box-shadow: 2px 2px 10px 2px #ddd;
    cursor: pointer;
    padding: 8px 10px;
    display: block;
    width: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #fff;

    &:hover {
      color: #888;
    }
  }

  .content {
    background: #fff;
    padding: 10px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .el-row {
    background: #f5f5f5;
    padding: 5px;

    .el-col {
      padding: 15px 10px;
    }
  }

  .detail-row {
    flex-grow: 1;
    overflow-y: auto;

    .el-col {
      display: flex;

      span {
        display: block;
        min-width: 80px;
        width: 80px;
      }
    }
  }

  /deep/.quill-editor {
    background: #fff;
    line-height: initial;
    width: 100%;

    .ql-toolbar {
      display: none;
    }

    .ql-container,
    .ql-editor {
      height: 300px;
    }

    .ql-container {
      border-top: 1px solid #ccc;
    }
  }
}
</style>
