var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "visit-detail" }, [
    _c(
      "span",
      {
        staticClass: "back-icon",
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      },
      [_c("i", { staticClass: "iconfont its_gy_fanhui" })]
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h3", [_vm._v("订单详情")]),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v(_vm._s(_vm.info.lineName)),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v(
                _vm._s(
                  _vm.orderStatusList.find(
                    (t) => t.value == _vm.info.orderStatus
                  ).label
                )
              ),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _vm._v("订单编号：" + _vm._s(_vm.info.orderId)),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("下单人：" + _vm._s(_vm.info.passengerName)),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("联系电话：" + _vm._s(_vm.info.passengerPhone)),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("乘客数量：" + _vm._s(_vm.info.passengerCount) + " "),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("有效乘车人数：" + _vm._s(_vm.info.validPassengersNum)),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _vm._v("下单时间：" + _vm._s(_vm.info.orderCreateTime)),
            ]),
          ],
          1
        ),
        _c("h3", [_vm._v("回访信息")]),
        _c(
          "el-row",
          { staticClass: "detail-row" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("回访时间：")]),
              _c("div", [_vm._v(_vm._s(_vm.info.interviewTime))]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("被访人：")]),
              _c("div", [_vm._v(_vm._s(_vm.info.interviewee))]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("回访人：")]),
              _c("div", [_vm._v(_vm._s(_vm.info.interviewer))]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("回访方式：")]),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.info.interviewWay == 0 ? "电话回访" : "微信回访")
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 24 } }, [
              _c("span", [_vm._v("回访内容：")]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.interviewContent == 0
                        ? "产品使用情况"
                        : "客诉处理"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("span", [_vm._v("回访记录：")]),
                _c("quill-editor", {
                  ref: "myTextEditor",
                  attrs: { options: _vm.editorOption, disabled: true },
                  model: {
                    value: _vm.info.interviewRecord,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "interviewRecord", $$v)
                    },
                    expression: "info.interviewRecord",
                  },
                }),
              ],
              1
            ),
            _c("el-col", { attrs: { span: 24 } }, [
              _c("span", [_vm._v("附件：")]),
              _c(
                "div",
                { staticClass: "files" },
                [
                  _vm._l(_vm.info.Files, function (item, index) {
                    return [
                      item.url && item.type.indexOf("image") != -1
                        ? _c("li", { key: index }, [
                            _c("i", { staticClass: "el-icon-picture" }),
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    src: _vm.fileBaseUrl + item.url,
                                    "preview-src-list": [
                                      _vm.fileBaseUrl + item.url,
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : item.url && item.type.indexOf("audio") != -1
                        ? _c("li", { key: index }, [
                            _c("i", { staticClass: "el-icon-microphone" }),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.fileBaseUrl + item.url,
                                  download: item.name,
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        : item.url && item.type.indexOf("video") != -1
                        ? _c("li", { key: index }, [
                            _c("i", { staticClass: "el-icon-video-camera" }),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.fileBaseUrl + item.url,
                                  download: item.name,
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }